.button {
  cursor: pointer;
  font-weight: 500;
  color: var(--color-white);
  border-radius: var(--border-radius-sm);
  padding: 11px 1.25rem;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
}
.sm {
  padding: 11px 1.25rem;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.md {
  padding: 11px 1.25rem;
}
.lg {
  padding-bottom: 17px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 17px;
}
.primary {
  background: var(--color-primary);
}
.secondary {
  background: var(--color-button-secondary);
  font-weight: 400;
}
.error {
  font-weight: 400;
}
