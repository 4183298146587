.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1.5rem 0;
}
.social {
  display: flex;
  align-items: center;
  li {
    margin-left: 1.2rem;
    @media screen and (max-width: 768px) {
      margin-left: 1rem;
    }
    svg {
      height: 22px;
      @media screen and (max-width: 768px) {
        height: 20px;
      }
    }
  }
}
