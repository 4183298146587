.faq {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.faqList {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.faqItem {
  width: 30%;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  span {
    font-weight: bold;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
  }
}
