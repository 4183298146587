.try {
  margin-top: 5rem;
  text-align: center;
  box-sizing: border-box;
  padding: 3rem;
  border-top-left-radius: 64px;
  border-top-right-radius: 64px;
  background: linear-gradient(
    0.0000056456212718103416deg,
    var(--token-39970de2-5fe4-42ad-ab53-d8274d5c9ad9, var(--color-surface)) 0%,
    var(--token-cb02979d-a542-4279-9758-de55dd3af50c, #020312) 100%
  );
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  p {
    max-width: 750px;
    font-size: 1.2rem;
    margin: 0 auto;
    line-height: 1.8rem;
    color: var(--color-secondary);
  }
  button {
    align-self: center;
    display: inline-block;
    margin-top: 2rem;
    span {
      font-size: 1.2rem;
    }
  }
}
