.testimonial {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }
}
