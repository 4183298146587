.hero {
  margin-top: 3rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }

  h1 {
    font-weight: bold;
    margin-top: 2rem;
    line-height: 1.2;
    font-size: 4.8rem;
    @media screen and (max-width: 768px) {
      font-size: 13vw;
    }
    span {
      letter-spacing: 0.6px;
      color: var(--color-primary);
      text-transform: uppercase;
    }
  }
}
.desktopBR {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.preTitle {
  text-transform: uppercase;
  color: var(--color-secondary);
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.6px;
}

.description {
  margin-top: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6;
  color: var(--color-secondary);
  @media screen and (max-width: 768px) {
    font-size: 3.8vw;
  }
}
.form {
  margin-top: 2rem;

  button {
    margin-left: 0.5rem;
  }
  input {
    width: 270px;
    text-align: left;
  }
  p {
    font-size: 0.75rem;
    color: var(--color-secondary);
    @media screen and (max-width: 768px) {
      font-size: 0.6rem;
    }
  }
  form {
    @media screen and (max-width: 768px) {
      display: flex;
    }
  }
}

.founders {
  margin-top: 4rem;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  border-radius: 0.7rem;
  @media screen and (max-width: 768px) {
    margin-top: 2.5rem;
    padding: 1rem 0;
  }
  div {
    position: relative;
  }
  blockquote:before {
    color: rgba(255, 255, 255, 0.1);
    content: open-quote;
    font-size: 9em;
    top: 2rem;
    left: -2rem;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    position: absolute;
  }
  span {
    color: var(--color-secondary);
    font-weight: 500;
  }
  p {
    color: var(--color-secondary);
    font-size: 1.05rem;
    max-width: 500px;
    position: relative;
    font-style: italic;
    &:before {
      content: '';
      display: block;
    }
  }
}
.founderScontainer {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}
.foundersAvatar {
  display: flex;
  min-width: 100px;
  align-items: center;
  margin-right: 1rem;
  text-align: center;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    border: 2px solid var(--color-surface);
    @media screen and (max-width: 768px) {
      width: 50px;
      height: 50px;
    }
    &:last-child {
      margin-left: -20px;
    }
  }
}

.projects {
  margin-top: 5rem;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
  h2 {
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 2.6rem;
    @media screen and (max-width: 768px) {
      font-size: 7vw;
    }
  }
}

.projectsList {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  column-gap: 2rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
}
.projectCardContent {
  box-sizing: border-box;
  padding: 2rem;
}
.projectCard {
  // height: 200px;
  background: linear-gradient(108.49deg, rgba(3, 56, 99, 0.3) 0%, rgba(20, 107, 77, 0.3) 100%);
  margin-bottom: 2rem;
  border-radius: 0.5rem;
}

.projectCardBadge {
  border-radius: 20px;
  display: inline-block;
  padding: 0.05rem 1rem;
  background: var(--color-secondary);
  font-size: 0.8rem;
  color: var(--color-black);
  margin-right: 0.5rem;
}

.projectCardTitle {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1rem;
}
.projectCardDescription {
  margin-top: 0.5rem;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: var(--color-secondary);
}

.projectCardLink {
  margin-top: 1.1rem;
  font-size: 1.1rem;
  color: var(--color-primary);
  a {
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.5rem;
      transition: all 0.3s ease;
    }
  }
  &:hover {
    svg {
      margin-left: 0.7rem;
    }
  }
}
.projectCardImage {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  height: 325px;
  @media screen and (max-width: 768px) {
    height: 220px;
  }
  img {
    width: 90%;
    margin-left: auto;
    overflow: hidden;
    border-radius-top-left: 10px;
    @media screen and (max-width: 768px) {
      height: auto;
      width: 95%;
      object-fit: cover;
    }
  }
}

.projectCardImageCentered {
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 325px;
  @media screen and (max-width: 768px) {
    height: 220px;
  }
  img {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    @media screen and (max-width: 768px) {
      height: auto;
      width: 95%;
      object-fit: cover;
    }
  }
}

.input {
  font-size: 0.875rem;
  line-height: 160%;
  padding: 0.68rem 1rem;
  border-radius: var(--border-radius-sm);
  background-color: var(--color-grey);
  border: 1px solid var(--color-border-input);
  color: var(--color-grey-light);
}

.mobileBR {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.services {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  column-gap: 2rem;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  & > div {
    flex-grow: 1;
  }
}
.serviceItem {
  background: linear-gradient(108.49deg, rgba(3, 56, 99, 0.3) 0%, rgba(20, 107, 77, 0.3) 100%);
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.5rem;
  h3 {
    font-weight: 500;
    margin-bottom: 0.2rem;
  }
  p {
    color: var(--color-secondary);
  }
}
.servicePreview {
  position: relative;
  height: 217px;
  box-sizing: border-box;
  padding: 1rem;
  margin-top: 1rem;
  background: #19181b;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  @media screen and (max-width: 768px) {
    height: auto;
  }
}

.stripe {
  box-sizing: border-box;
  padding: 1rem;

  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(64px) saturate(1.5);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.stripeImage {
  width: 36px;
  img {
    width: 36px;
    border-radius: 8px;
  }
}

.stripeContent {
  line-height: 1.5;
  font-size: 0.875rem;
  b,
  p {
    font-weight: 500;
  }
  div {
    font-size: 0.785rem;
    font-weight: 500;
  }
}

.serviceSeo {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    gap: 0.5rem;
    align-items: flex-start;
  }
}

.serviceSeoItem {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.serviceSeoItemCircle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 3px solid rgb(74, 222, 128);
  color: rgb(74, 222, 128);
  font-size: 1.5rem;
  line-height: 58px;
  background: #192a21;
  @media screen and (max-width: 768px) {
    width: 48px;
    height: 48px;
    line-height: 42px;
    font-size: 0.875rem;
  }
}
.serviceSeoItemTitle {
  font-size: 0.725rem;
  line-height: 1;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 0.625rem;
  }
}
