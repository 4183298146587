.box {
  background: linear-gradient(108.49deg, rgba(3, 56, 99, 0.3) 0%, rgba(20, 107, 77, 0.3) 100%);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 380px;
  border: 1px solid var(--tabs-bg);
  border-radius: 1.5rem;

  @media screen and (max-width: 768px) {
    width: 280px;
    gap: 0.5rem;
    padding: 1.5rem;
  }
  span {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  p {
    color: var(--color-secondary);
    font-size: 0.875rem;
  }
}
.author {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: auto;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
}
.authorName {
  font-weight: bold;
}
