.stack {
  margin: 3rem auto;
}
.lineItem {
  border-radius: 999px;
  box-sizing: border-box;
  padding: 16px 24px;
  background-color: #020312;
  white-space: nowrap;
  display: flex;
  font-weight: 500;
  border: 1px solid var(--color-border);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.line {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: -1rem 0;
}
