:root {
  --color-primary: #76b744;
  --color-secondary: #a1a1aa;
  --color-surface: #131313;
  --color-surface-light: rgba(34, 40, 56, 0.5);
  --color-surface-lighter: linear-gradient(0deg, #1a202f 100%, rgba(26, 32, 47, 0) 672.83%);
  --color-text: #fff;
  --color-white: #fff;
  --color-black: #000;
  --color-grey: #222838;
  --color-grey-light: #939db8;
  --color-red: #d93d3d;

  --color-border: #222838;

  --color-border-input: #2f3647;
  --color-button-secondary: rgb(41, 48, 64);

  --text-gradient: radial-gradient(54.78% 305% at 50% 50%, var(--color-grey-light) 0, var(--color-primary) 100%);

  --border-radius-sm: 0.4rem;

  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
  --shadow-inset: rgba(255, 255, 255, 0.5);

  --popup-shadow: 0 12px 28px 0 var(--shadow-2), 0 2px 4px 0 var(--shadow-1);
}

html {
  background-color: var(--color-surface);
  background: linear-gradient(to bottom left, transparent 50%, #000 50.5%),
    linear-gradient(to bottom right, var(--color-surface) 50%, #171717 50.5%);
  color: var(--color-text);
  font-size: 16px;
  line-height: 150%;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}
.turnstile {
  display: none;
}
body {
  overflow-x: hidden;
}
*:disabled {
  opacity: 0.5;
}
